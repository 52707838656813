/*global window, WOW,Glide, setTimeout, document, getSiblings ,setInterval, clearInterval,getElements,getElement,getNextSibling,getPrevSibling,setAttributes,getComputedStyle,pageDirection,console*/
/*jslint es6 */
/*===== Tornado Design Features =====*/
document.addEventListener('DOMContentLoaded', function () {
    'use strict';
    //==== WOW JS Init ====//
    function afterReveal(el) {
        el.addEventListener('animationend', function () {
            el.classList.add("viewActive");
        });
    }

    var wowFire = new WOW({callback: afterReveal});
    wowFire.init();

    tornadoUtilites();

    //==== Home Slider Pagination ====//
    var slidesNum = getElements('body:not(.wp-admin) .home-slider .glide-slide');
    if (slidesNum !== null) {
        //====> Select the Track <====//
        var trackElement = getElement('body:not(.wp-admin) .home-slider .glide-track');
        if (trackElement !== null) {
            //====> Create Pagination <=====//
            var pagiContainer = document.createElement('ul');
            pagiContainer.classList.add('glide-bullets');
            pagiContainer.setAttribute('data-glide-el','controls[nav]');
            insertAfter(pagiContainer,trackElement);
        }
        //===== Create Pagination Items ======//
        Array.from(slidesNum).forEach(function (slidesNum,i) {
            var pagiItem = document.createElement('li'),
                index = i+1;
            pagiItem.classList.add('glide-bullet');
            pagiItem.setAttribute('data-glide-dir','='+i);
            pagiItem.innerHTML = '<button>0'+index+'</button>';
            pagiContainer.appendChild(pagiItem);
        });
    }

    //==== Home Slider ====//
    var homeSliderElement = getElements('body:not(.wp-admin) .home-slider');
    Array.from(homeSliderElement).forEach(function (homeSliderElement) {
        var homeSlider = new Glide(homeSliderElement, {
            type: 'slider',
            autoplay: 7000,
            animationDuration: 1000,
            hoverpause: true,
            perView: 1
        });

        homeSlider.mount();

        if (pageDirection === 'rtl') {
            homeSlider.update({direction: 'rtl'});
        }
    });

    //==== Store Slider ====//
    var storeSliderElement = getElements('.store-slider');
    Array.from(storeSliderElement).forEach(function (storeSliderElement) {
        var storeSlider = new Glide(storeSliderElement, {
            type: 'carousel',
            autoplay: 7000,
            animationDuration: 1000,
            hoverpause: true,
            perView: 3,
            gap: 30,
            breakpoints :{
                990: {perView: 2,peek: {before:100,after:100}},
                650: {perView: 2,peek: {before:0,after:0}},
                370: {perView: 1},
            }
        });

        storeSlider.mount();

        if (pageDirection === 'rtl') {
            storeSlider.update({direction: 'rtl'});
        }
    });

    var contact7dir = getElements('.wpcf7');
    Array.from(contact7dir).forEach(element=>{
        element.removeAttribute('dir');
    });

    //==== Testimonials Slider ====//
    var testimonialsSliderElement = getElements('.testimonials-slider');
    Array.from(testimonialsSliderElement).forEach(function (testimonialsSliderElement) {
        var testimonialsSlider = new Glide(testimonialsSliderElement, {
            type: 'carousel',
            autoplay: 7000,
            animationDuration: 1000,
            hoverpause: true,
            perView: 2,
            gap: 30,
            breakpoints :{
                650: {perView: 1,peek: {before:0,after:0}},
            }
        });

        testimonialsSlider.mount();

        if (pageDirection === 'rtl') {
            testimonialsSlider.update({direction: 'rtl'});
        }
    });

    var testimonialsSliderSingle = getElements('.testimonials-slider-2');
    Array.from(testimonialsSliderSingle).forEach(function (testimonialsSliderSingle) {
        var testimonialsSingle = new Glide(testimonialsSliderSingle, {
            type: 'slider',
            autoplay: 7000,
            animationDuration: 1000,
            hoverpause: true,
            perView: 1,
        });

        testimonialsSingle.mount();

        if (pageDirection === 'rtl') {
            testimonialsSingle.update({direction: 'rtl'});
        }
    });

    //==== Blog Slider ====//
    var blogSliderElement = getElements('.blog-slider');
    Array.from(blogSliderElement).forEach(function (blogSliderElement) {
        var blogSlider = new Glide(blogSliderElement, {
            type: 'carousel',
            autoplay: 7000,
            animationDuration: 1000,
            hoverpause: true,
            perView: 2,
            gap: 30,
            breakpoints :{
                810: {perView: 1,peek: {before:100,after:100}},
                490: {perView:1, peek: {before:0,after:0}},
            }
        });

        blogSlider.mount();

        if (pageDirection === 'rtl') {
            blogSlider.update({direction: 'rtl'});
        }
    });

    //==== Categories Slider ====//
    var categoriesSliderElement = getElements('.categories-slider');
    Array.from(categoriesSliderElement).forEach(function (categoriesSliderElement) {
        var categoriesSlider = new Glide(categoriesSliderElement, {
            type: 'carousel',
            autoplay: 7000,
            animationDuration: 1000,
            hoverpause: true,
            perView: 4,
            gap: 30,
            breakpoints :{
                990: {perView: 3},
                680: {perView: 2},
                440: {perView: 1},
            }
        });

        categoriesSlider.mount();

        if (pageDirection === 'rtl') {
            categoriesSlider.update({direction: 'rtl'});
        }
    });

    //==== Portfolio Slider ====//
    var portfolioSliderElement = getElements('body:not(.wp-admin) .portfolio-slider');
    Array.from(portfolioSliderElement).forEach(function (portfolioSliderElement) {
        var portfolioSlider = new Glide(portfolioSliderElement, {
            type: 'carousel',
            animationDuration: 1000,
            hoverpause: true,
            perView: 1,
        });

        portfolioSlider.mount();

        if (pageDirection === 'rtl') {
            portfolioSlider.update({direction: 'rtl'});
        }
    });

    //==== Design Hacks =====//
    var homeHero = getElement('.main-header + .hero-section'),
        heroSlider = getElement('.home-slider'),
        headerCache = getElement('.main-header');
    if (homeHero !== null) { homeHero.insertBefore(headerCache, heroSlider); };
    //==== Wordpress Adminbar and Sticky Header =====//
    var adminBar = getElement('#wpadminbar');
    if(adminBar !== null){adminBar.parentNode.classList.add('adminbar-activate');}
    //==== Fixing Store Details Height ====//
    setTimeout(function () {
        var storeDetails = getElements('.store-details');
        Array.from(storeDetails).forEach(function (storeDetails) {
            var detailsHeight = storeDetails.offsetHeight,
                detailsSlide = storeDetails.querySelectorAll('.item .content-box');
            Array.from(detailsSlide).forEach(function (detailsSlide) {
                detailsSlide.style.minHeight = detailsHeight + 'px';
            });
        });
    }, 1500);

    //==== Fix Controls Sibling Event ====//
    var notControl = getElements('[data-glide-el="controls"] *:not([data-glide-dir])');
    Array.from(notControl).forEach(function(notControl){
        notControl.addEventListener('click',function(e){
            var trueLink = notControl.getAttribute('href');
            window.location.replace(trueLink);
        });
    });

    //==== Show Gutenberg Video Controls ====//
    var respVid = getElement('.responsive-video video');
    if (respVid !== null) respVid.setAttribute('controls','true');

    //==== Remove Archives from the Title ====//
    var pageTitle = getElement('.page-head h1');
    if (pageTitle !== null) {
        var oldTitle = pageTitle.textContent,
            newTitle = oldTitle.replace(/Archives|Archive|page|[0-9]|of/gi,'');
        pageTitle.textContent = newTitle;
    }

    if (pageDirection == 'rtl') {
        var breadcrumbElements = getElements('.breadcrumb .breadcrumb_last');
        Array.from(breadcrumbElements).forEach(function (breadcrumbElement) {
            var elemTxt = breadcrumbElement.textContent;
            elemTxt = elemTxt.replace("Page", "");
            breadcrumbElement.textContent = elemTxt;
        });
    }

    var directionElement = getElement('[dir]');
    if (directionElement) { directionElement.removeAttribute('dir'); }

    var siteWraper = getElement('.site-wraper');
    if(siteWraper) {
        var floatingWhats = siteWraper.parentNode.querySelector('.floating-whats');
        if (floatingWhats) { floatingWhats.classList.add('push-up'); }
    }

    var halfItems = getElements('.sub-menu li');
    if(halfItems.length >= 10) halfItems[0].parentNode.classList.add('halfitems');
});

//==== Details Slider ====//
var detailsSliderElement = getElements('body:not(.wp-admin) .details-slider');
Array.from(detailsSliderElement).forEach(function (detailsSliderElement) {
    var detailsSlider = new Glide(detailsSliderElement, {
        type: 'carousel',
        animationDuration: 1000,
        hoverpause: true,
        perView: 1,
    });

    detailsSlider.mount();

    if (pageDirection === 'rtl') {
        detailsSlider.update({ direction: 'rtl' });
    }
});